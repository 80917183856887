// src/components/IframeEmbed.jsx
import React from "react";
import PropTypes from "prop-types";
import "./IframeEmbed.css"; // Optional: For custom styling

const IframeEmbed = ({ src, title, className }) => (
  <div className="iframe-container">
    <iframe
      src={src}
      title={title}
      className={className}
      frameBorder="0"
      loading="lazy"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin allow-top-navigation-by-user-activation allow-popups"
      allow="clipboard-write"
    ></iframe>
  </div>
);

IframeEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

IframeEmbed.defaultProps = {
  className: "",
};

export default IframeEmbed;
