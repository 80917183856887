// src/screens/ArcadePage.jsx
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Subscription from "../../components/Subscription/Subscription";
import { Helmet } from "react-helmet-async";
import IframeEmbed from "../../components/IframeEmbed"; // Reusable iframe component
import "./style.css"; // Optional: For custom styling

const DocumentationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <Helmet>
        <title>Trailblu Product Demos</title>
        <meta
          name="description"
          content="Explore Trailblu's product demos and see our solutions in action."
        />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Header />
      <div className="arcade-container">
        <IframeEmbed
          src="https://app.arcade.software/share/collections/eL8gqTWlAwu3M8xEahYE?embed&show_copy_link=true&force_no_header=true"
          title="Trailblu Product Demos"
          className="arcade-collection"
        />
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default DocumentationPage;
