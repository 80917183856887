import React, { useState } from "react";
import Logo from "../../assets/Logo.png";
import MenuIcon from "../../assets/menuIcon.png";
import { Button } from "../Button/Button";
import "./style.css";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleMenuItemClick = () => {
    setMenuVisible(false);
  };

  return (
    <div className="HEADER">
      <div className="HEADER-CONTENT">
        <a href="/">
          <img src={Logo} className="tb" alt="Trailblu Logo" />
        </a>
        <div className="frame-3">
          <img
            className="menu-icon"
            src={MenuIcon}
            alt="Trailblu Menu Icon"
            style={{ width: "40px", height: "auto", cursor: "pointer" }}
            onClick={toggleMenu}
          />
          {menuVisible && (
            <div className="menu-dropdown">
              <ul className="menu-list">
                <li>
                  <a href="/blog" onClick={handleMenuItemClick}>
                    <Button
                      className="design-component-instance-node-small"
                      stateProp="normal"
                      style="text"
                      text="Blog"
                      type="regular"
                    />
                  </a>
                </li>
                <li>
                  <a href="/documentation" onClick={handleMenuItemClick}>
                    <Button
                      className="design-component-instance-node-small"
                      stateProp="normal"
                      style="text"
                      text="Documentation"
                      type="regular"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://web.trailblu.com/login"
                    onClick={handleMenuItemClick}
                  >
                    <Button
                      className="design-component-instance-node-small"
                      stateProp="normal"
                      style="text"
                      text="Log in"
                      type="regular"
                    />
                  </a>
                </li>
                <li>
                  <a href="/request-a-demo">
                    <Button
                      className="button-2-small"
                      stateProp="normal"
                      style="solid"
                      text="Try for Free"
                      type="cta"
                    />
                  </a>

                  {/* <a href="/#section7" onClick={handleMenuItemClick}>
                                        <Button className="button-2-small" stateProp="normal" style="solid" text="Request a demo" type="cta" />
                                    </a> */}
                </li>
                {/* Add more menu items as needed */}
              </ul>
            </div>
          )}
          <a href="/blog" onClick={handleMenuItemClick}>
            <Button
              className="design-component-instance-node-large"
              stateProp="normal"
              style="text"
              text="Blog"
              type="regular"
            />
          </a>
          <a href="/documentation" onClick={handleMenuItemClick}>
            <Button
              className="design-component-instance-node-large"
              stateProp="normal"
              style="text"
              text="Documentation"
              type="regular"
            />
          </a>
          <a href="https://web.trailblu.com/login">
            <Button
              className="design-component-instance-node-large"
              stateProp="normal"
              style="text"
              text="Log in"
              type="regular"
            />
          </a>
          <a href="/request-a-demo">
            <Button
              className="button-2-large"
              stateProp="normal"
              style="solid"
              text="Try for Free"
              type="cta"
            />
          </a>
          {/* <a href="/#section7">
                        <Button className="button-2-large" stateProp="normal" style="solid" text="Request a demo" type="cta" />
                    </a> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
