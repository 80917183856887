import ProfileIcon from "./assets/profile.jpg";
import VisualTestingImage from "./assets/visualTesting.png";
import GenerativeAIimage from "./assets/generativeAI.png";
import QatarEvent from "./assets/qatar_event.png";
import HowToMasterImage from "./assets/p21.png";
import FlutterTestingImage from "./assets/flutterTesting.png";
import SelfHealingImage from "./assets/p31.png";
import RevolutionizeTestingAIImage from "./assets/revolutionizetestingAI1.png";
import CodelessAutomatedImage from "./assets/codelessAutomated.png";
import ContinuousTestingImage from "./assets/ContinuousTesting2.png";
import RegressionTestingImage from "./assets/RegressionTesting1.png";

/* Added Missing Image Imports */
import ScalingAIImage from "./assets/scalingSoftwareTestingAI.png";
import EndToEndTestingImage from "./assets/endToEndTesting.png";
import CrossDeviceTestingImage from "./assets/crossDeviceTesting.png";
import ProfitCenterTestingImage from "./assets/profitCenterTesting.png";
import SeleniumPlaywrightTestingImage from "./assets/seleniumPlaywrightTesting.png";
import DeviceFarmImage from "./assets/deviceFarm.png";
import TestAutomationImage from "./assets/testAutomation.png";
import ValidationVerificationImage from "./assets/validationVerification.png";

/* Existing Content Imports */
import CodelessAutomated from "./Blogs/CodelessAutomated.md";
import QatarEventBlog from "./Blogs/QatarEvent.md";
import HowToMaster from "./Blogs/HowToMaster.md";
import TrailbluIntroduces from "./Blogs/TrailbluIntroduces.md";
import AIPoweredSelfHealing from "./Blogs/AIPoweredSelfHealing.md";
import GenerativeAI from "./Blogs/GenerativeAI.md";
import FlutterTesting from "./Blogs/FlutterTesting.md";
import RevolutionizeTestingAI from "./Blogs/RevolutinizeTestingAI.md";
import RevolutionizeTestingAI2 from "./Blogs/RevolutinizeTestingAI2.md";
import ContinuousTesting from "./Blogs/ContinuousTesting.md";
import RegressionTesting from "./Blogs/RegressionTesting.md";

/* Added Missing Content Imports */
import ScalingAIContent from "./Blogs/ScalingSoftwareTestingAI.md";
import EndToEndTestingContent from "./Blogs/EndToEndTesting.md";
import CrossDeviceTestingContent from "./Blogs/CrossDeviceTesting.md";
import VisualTestingContent from "./Blogs/VisualTestingContent.md";
import ProfitCenterTestingContent from "./Blogs/ProfitCenterTesting.md";
import SelfHealingContent from "./Blogs/SelfHealingContent.md";
import SeleniumPlaywrightContent from "./Blogs/SeleniumPlaywrightContent.md";
import DeviceFarmContent from "./Blogs/DeviceFarmContent.md";
import TestAutomationContent from "./Blogs/TestAutomationContent.md";
import ValidationVerificationContent from "./Blogs/ValidationVerificationContent.md";

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
};

const ScalingAIContent = ScalingAIContent.replace("{{IMAGE_SRC}}", ScalingAIImage);

const blogPosts = [
    {
        label: "Navigating Software Testing: Validation vs. Verification",
        tag: "Software Testing",
        blogImage: ValidationVerificationImage,
        title: "Navigating Software Testing: Validation vs. Verification",
        metaTitle: "Validation vs Verification in Software Testing: Key Differences Explained",
        metaDescription: "Learn the key differences between validation and verification in software testing. Understand why both processes are essential for delivering high-quality software and how Trailblu ensures quality through automated testing.",
        author: "Trailblu",
        date: "Nov 25, 2024",
        readTime: "3",
        content: ValidationVerificationContent,
        authorImageUrl: ProfileIcon
        }
        
    ,
  {
    label: "Scaling Software Testing with AI",
    tag: "Artificial Intelligence",
    blogImage: ScalingAIImage,
    title:
      "Scaling Software Testing with AI: Revolutionizing Efficiency and Flexibility",
    metaTitle: "AI-Driven Software Testing: Scale Efficiently with AI Solutions",
    metaDescription:
      "Discover how AI-powered testing methodologies enable scalable, flexible, and efficient testing practices for modern software applications. Learn how Trailblu leads the way in AI-driven testing.",
    author: "Trailblu",
    date: "Nov 23, 2024",
    readTime: "5",
    //content: processedContent,
    content: ScalingAIContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "End-to-End Testing",
    tag: "Quality Assurance",
    blogImage: EndToEndTestingImage,
    title: "What is End-to-End Testing?",
    metaTitle:
      "End-to-End Testing: Ensure Seamless Application Performance Across Workflows",
    metaDescription:
      "Discover the importance of End-to-End Testing for verifying complete application flows and ensuring seamless user experience. Learn strategies, benefits, and how Trailblu empowers effective E2E Testing.",
    author: "Trailblu",
    date: "Nov 11, 2024",
    readTime: "4",
    content: EndToEndTestingContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Cross Device Testing",
    tag: "Cross Platform Solutions",
    blogImage: CrossDeviceTestingImage,
    title: "The Ultimate Guide to Cross Device Testing",
    metaTitle:
      "Cross Device Testing: Ensure Seamless User Experiences Across Platforms",
    metaDescription:
      "Learn how to perform effective cross device testing to ensure your web and mobile applications work flawlessly across all devices. Discover strategies, challenges, and tools like Trailblu for seamless testing.",
    author: "Trailblu",
    date: "Nov 4, 2024",
    readTime: "7",
    content: CrossDeviceTestingContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Visual Testing",
    tag: "Visual Testing Solutions",
    blogImage: VisualTestingImage,
    title: "Visual Testing Challenges: A Trailblu Solution",
    metaTitle: "Visual Testing Challenges and Trailblu's AI-Powered Solution",
    metaDescription:
      "Discover how Trailblu tackles visual testing challenges with AI-driven solutions, ensuring cross-browser consistency, handling dynamic content, and reducing false positives.",
    author: "Trailblu",
    date: "Oct 28, 2024",
    readTime: "7",
    content: VisualTestingContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Software Testing",
    tag: "Software Testing",
    blogImage: ProfitCenterTestingImage,
    title: "Why Software Testing Should Be Considered a 'Profit Center'",
    metaTitle: "Software Testing Profit Center | Trailblu",
    metaDescription:
      "Learn how software testing can drive profitability by enhancing quality, accelerating time-to-market, and reducing costs through automation. Discover key industry insights and future trends.",
    author: "Trailblu",
    date: "Oct 24, 2024",
    readTime: "6",
    content: ProfitCenterTestingContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Self-Healing Test Automation",
    tag: "Self-Healing Testing",
    blogImage: SelfHealingImage,
    title: "Understanding Self-Healing for Test Automation",
    metaTitle: "Self-Healing in Test Automation: AI-Powered Efficiency with Trailblu",
    metaDescription:
      "Explore the benefits of self-healing test automation and how Trailblu's AI-powered solutions improve test stability, reduce maintenance, and accelerate time-to-market.",
    author: "Trailblu",
    date: "Oct 21, 2024",
    readTime: "8",
    content: SelfHealingContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Selenium and Playwright",
    blogImage: SeleniumPlaywrightTestingImage,
    title: "Unlocking the Power of Selenium and Playwright with Trailblu",
    metaTitle: "Enhance Web Testing with Trailblu, Selenium, and Playwright",
    metaDescription:
      "Explore how Trailblu’s AI-powered test automation enhances Selenium and Playwright for efficient, scalable, and no-code web testing solutions.",
    author: "Trailblu",
    date: "Oct 7, 2024",
    readTime: "6",
    content: SeleniumPlaywrightContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Cross-Platform Testing",
    tag: "Cross-Platform Testing",
    blogImage: DeviceFarmImage,
    title:
      "Maximize Your Testing Strategy with Trailblu’s Real Mobile Device Farm and Browser Farms",
    metaTitle: "Optimize Testing with Trailblu’s Device and Browser Farms",
    metaDescription:
      "Discover how Trailblu’s Real Device and Browser Farms enhance testing accuracy, cross-platform compatibility, and reduce time-to-market for your applications.",
    author: "Trailblu",
    date: "Oct 3, 2024",
    readTime: "7",
    content: DeviceFarmContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Test Automation Importance",
    tag: "Test Automation Benefits",
    blogImage: TestAutomationImage,
    title: "What is Test Automation and Why Does it Matter?",
    metaTitle: "Why Test Automation Matters | Trailblu",
    metaDescription:
      "Discover the importance of test automation in modern software development. Learn how automated testing enhances quality, reduces time-to-market, and improves efficiency.",
    author: "Trailblu",
    date: "Sep 30, 2024",
    readTime: "6",
    content: TestAutomationContent,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: RevolutionizeTestingAIImage,
    title:
      "Revolutionizing Software Testing with AI: Expanding the Horizons (Part 2)",
    metaTitle: "Software Testing with AI | Trailblu",
    metaDescription:
      "Discover how AI is transforming software testing, from performance and security testing to continuous testing and predictive analytics. Explore real-world examples and future trends in AI-augmented testing.",
    author: "Trailblu",
    date: "Sep 23, 2024",
    readTime: "5",
    content: RevolutionizeTestingAI2,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "DevOps",
    tag: "DevOps",
    blogImage: RegressionTestingImage,
    title: "Regression Testing",
    metaTitle: "Regression Testing | Trailblu",
    metaDescription:
      "Discover why Regression Testing is crucial for enabling early bug detection, improved collaboration, and seamless code deployment with Trailblu's expert solutions.",
    author: "Trailblu",
    date: "Sep 3, 2024",
    readTime: "5",
    content: RegressionTesting,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "DevOps",
    tag: "DevOps",
    blogImage: ContinuousTestingImage,
    title: "Why Continuous Testing is a Must-Have for Every Developer",
    metaTitle: "Why Continuous Testing is Essential for Every Developer | Trailblu",
    metaDescription:
      "Discover why Continuous Testing is crucial for developers to maintain speed and quality in software development. Learn how it enables early bug detection, improved collaboration, and seamless code deployment with Trailblu's expert solutions.",
    author: "Trailblu",
    date: "Aug 13, 2024",
    readTime: "5",
    content: ContinuousTesting,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: RevolutionizeTestingAIImage,
    title:
      "Revolutionizing Software Testing with AI: 6 Key Benefits for Your Business (Part 1)",
    metaTitle: "Software Testing with AI | Trailblu",
    metaDescription:
      "Discover how AI is transforming software testing, boosting efficiency, accuracy, and coverage. Explore 6 key benefits of AI-augmented testing in this insightful blog post.",
    author: "Trailblu",
    date: "July 30, 2024",
    readTime: "5",
    content: RevolutionizeTestingAI,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: GenerativeAIimage,
    title: "Unveiling the Future of Software Testing with Generative AI at Trailblu",
    metaTitle: "Generative AI in Software Testing | Trailblu",
    metaDescription:
      "Explore Generative AI’s role in software testing and test automation. Read about important use cases and Trailblu’s innovative approach!",
    author: "Trailblu",
    date: "January 29, 2024",
    readTime: "5",
    content: GenerativeAI,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Event",
    tag: "Event",
    blogImage: QatarEvent,
    title: "Web Summit Qatar: Trailblu's Insight into Future Tech",
    metaTitle:
      "Trailblu's Exciting Venture at Web Summit Qatar 2024: Unveiling Tech Innovations",
    metaDescription:
      "Join Trailblu at the Web Summit Qatar 2024 for industry insights and influential speakers. Explore our booth for tech innovations, partnerships, and key takeaways.",
    author: "Trailblu",
    date: "February 2, 2024",
    readTime: "3",
    content: QatarEventBlog,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: FlutterTestingImage,
    title: "Testing the Uncharted Territory of Flutter Apps",
    metaTitle: "Flutter Testing: Trailblu's Solution for Seamless Automation",
    metaDescription:
      "Elevate your Flutter app testing with Trailblu's innovative automation solution. Request a demo for a glimpse into the future of Flutter testing.",
    author: "Trailblu",
    date: "January 11, 2024",
    readTime: "3",
    content: FlutterTesting,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Visual Testing",
    tag: "Visual Testing",
    blogImage: VisualTestingImage,
    title: "Flawless UI Every Time: Introducing Trailblu's Visual Testing Revolution",
    metaTitle: "Visual Testing Feature for Superior UI Consistency | TRAILBLU",
    metaDescription:
      "Trailblu's Visual Testing feature is designed to enhance UI consistency across platforms. Automated, efficient, and precise – perfect for developers and designers.",
    author: "Trailblu",
    date: "November 25, 2023",
    readTime: "3",
    content: TrailbluIntroduces,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Codeless Automation",
    tag: "Codeless Automation",
    blogImage: HowToMasterImage,
    title: "Codeless Testing Best Practices: A Complete Guide to Mastery",
    metaTitle: "How to Master Codeless Test Automation | TRAILBLU",
    metaDescription:
      "Learn essential Trailblu tips for achieving effective and reliable tests, covering topics like test coverage, data quality, collaboration, and more.",
    author: "Trailblu",
    date: "April 5, 2023",
    readTime: "5",
    content: HowToMaster,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Test Automation",
    tag: "Test Automation",
    blogImage: SelfHealingImage,
    title: "AI-Powered Self-Healing: Tackling Test Flakiness Head-On",
    metaTitle: "AI-Powered Self-Healing for Test Flakiness | TRAILBLU",
    metaDescription:
      "Discover the impact of flaky locators and how AI-powered self-healing techniques revolutionize automated testing.",
    author: "Trailblu",
    date: "December 26, 2022",
    readTime: "6",
    content: AIPoweredSelfHealing,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: CodelessAutomatedImage,
    title: "Effortless Testing: The Future of Codeless Automation",
    metaTitle: "The Rise of Codeless Automation | TRAILBLU",
    metaDescription:
      "Streamline your testing process without coding, saving time and effort. Explore the simplicity of Trailblu for efficient mobile and web application testing.",
    author: "Trailblu",
    date: "December 5, 2022",
    readTime: "4",
    content: CodelessAutomated,
    authorImageUrl: ProfileIcon,
  },
];

export { createSlug, blogPosts };
